import React, { useEffect } from "react";
import { Heading, Paragraph } from "theme-ui";
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout";
import OwnedEventsList from "../components/OwnedEventsList";

const OwnedEventsPage = () => {

  return <Layout>
    <main>
      <Helmet title="My Events"/>
      <OwnedEventsList />
    </main>
  </Layout>;
}

export default OwnedEventsPage;

import React, { ReactNode } from "react";
import { IconButton } from "theme-ui";
import theme from '../theme';

type IconButtonProps = {
  onClick: () => void,
  children: ReactNode
}

export default (props: IconButtonProps) => (
  <IconButton sx={{
    color: theme.colors.primary,
    "&:hover": {
      color: theme.colors.highlight,
      cursor: 'pointer'
    }
  }} onClick={props.onClick}>
    {props.children}
  </IconButton>
)
import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider, Flex, Heading, Message, Paragraph, Text } from "theme-ui";
import { endpoint } from "../lib/endpoint";
import OwnedEventInList from "./OwnedEventInList";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import { Event, jsonToEvent } from "../types";
import ActionButton from "./ActionButton";
import LoadingIndicator from "./LoadingIndicator";
import {navigate} from "gatsby";

function useMyEvents() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<Array<Event>>([]);

  const [fetchAuthenticated, loggingIn] = useAuthenticatedFetch(`${endpoint}/owned-events`);

  useEffect(() => {
    if (!loading) {
      return;
    }
    fetchAuthenticated()
    .then(response => {
      console.log('response', response)
      if (!response.ok) {
        throw new Error('Response not ok'); // TODO: error handling
      }
      return response.text();
    })
    .then(body => {
      if (loading) {
        setData(JSON.parse(body).events.map(jsonToEvent));
        setLoading(false);
      }
    })
    .catch(setError);
    return () => setLoading(false);
  }, [loading, fetchAuthenticated]);

  return { isLoading: loading || loggingIn, error, events: data }
}

export default () => {

  const { isLoading, error, events } = useMyEvents();

  const refresh = useCallback(() => {
    location.reload();
  }, []);

  if (error) {
    return <section>
      <Message>
        <Text>Error: {error.message}</Text>
        <Divider/>
        <Text>Please <a href='#' onClick={refresh}>refresh</a> to try again.</Text>
      </Message>
      { error.stack &&
        <pre>{JSON.stringify(error.stack, null, 2)}</pre>
      }
    </section>
  }
  return (<>
    <Flex sx={{ alignItems: "center", pt: 2, pb: 2 }}>
      <Heading sx={{ pr: 2 }}>
        My Events
      </Heading>
      { !isLoading && events.length > 0 &&
        <ActionButton isValid={true} type='button' onClick={() => navigate('/owned-events/create')}>
          Add another event
        </ActionButton>
      }
    </Flex>
    <section>
      { isLoading && <LoadingIndicator /> }
      { !isLoading && events.length < 1 &&
        <>
          <Box>
            <Text>You did not create any events yet.</Text>
          </Box>
          <ActionButton isValid={true} type='button' onClick={() => navigate('/owned-events/create')}>
            Create my first event
          </ActionButton>
        </>
      }
      { !isLoading &&
        <>
          { events.map(e => (
            <OwnedEventInList key={e.id} event={e} />
          ))}
        </>
      }
    </section>
  </>);
}

import React, {useCallback, useState} from "react";
import { Box, Card, Heading, Text } from "theme-ui";
import { Event } from '@mui/icons-material'
import InlineEditButton from "./InlineEditButton";
import InlineShareButton from "./InlineShareButton";
import ReactMarkdown from "react-markdown";
import { navigate } from "gatsby";
import ActionButton from "./ActionButton";
import ActionShareButton from "./ActionShareButton";
import linkUrl from "../lib/linkUrl";
import LinkInApp from "./LinkInApp";
import OwnedEventListedStatusIndication from "./OwnedEventListedStatusIndication";
import EventDateAndTime from "./EventDateAndTime";

export default ({ event }: { event: any }) => {

  /*
  const [_isLoading, setLoading] = useState(false);
  const [fetchRegistrations, _isLoggingIn] = useAuthenticatedFetch(`${endpoint}/registrations-by-event/${event.id}`);
  const messageAll = useCallback(() => {
    setLoading(true);
    fetchRegistrations()
    .then(response => {
      setLoading(false);
      console.log('registrations, response', response);
      return response.text();
    }).then(responseBody => {
      const registrations = JSON.parse(responseBody);
      console.log('registrations', registrations);
      if (registrations.list.length < 1) {
        global.alert("This event has no registrations yet, so there is nobody to message here right now.");
        return;
      }
      const emails: string[] = registrations.list.map((e: any) => e.email);
      const body = `Hello
(fill in message content here)
Event: ${global.location.protocol}//${global.location.host}/events/${event.guid}
      `;
      const subject = `Message from your Organizer: ${event.title}`;
      global.location.href = `mailto:?bcc=${encodeURIComponent(emails.join(","))}`
        + `&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }).catch(err => {
      setLoading(false);
      alert(`Oops, this failed: ${err.message}`);
    });
  }, [event]);
  */

  console.info("OwnedEventInList, event", event);
  return (
    <Card sx={{ pt: 3 }}>
      <Heading>
        <LinkInApp to={'/owned-events/view/' + event.id}>
          <Box sx={{ display: 'inline', pr: 2 }}>
            <Event />
          </Box>
          <Text sx={{ pr: 2 }}>{event.title}</Text>
        </LinkInApp>
	<InlineEditButton onClick={() => {
	  navigate('/owned-events/edit/' + event.id, {
	    state: {
	      id: event.id
	    }
	  });
	}} />
	<InlineShareButton
	  title={event.title}
	  text={`Invitation: ${event.title}`}
	  url={`${linkUrl}/events/${event.guid}`}
	  onClick={() => console.log("shared")} />
      </Heading>
      <EventDateAndTime event={event} />
      <OwnedEventListedStatusIndication event={event} />
    </Card>
  );
}

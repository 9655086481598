import { Edit } from "@mui/icons-material";
import React from "react";
import IconButton from "./IconButton";

type EditButtonProps = {
  onClick: () => void
}

export default (props: EditButtonProps) => (
  <IconButton onClick={props.onClick}>
    <Edit/>
  </IconButton>
)
import React from "react";
import { Check, Share, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Text, Flex } from "theme-ui";
import LinkInApp from "./LinkInApp";

type Props = {
  event: {
    is_public: boolean,
    is_approved: boolean
  }
};

export default function({ event }: Props) {
  return (
    <Flex sx={{ pb: 2, pt: 2, flexDirection: "column" }}>
      <Flex>
        <Check />
        <Text ml={2}>
          Your event is ready for you to share with your audience.
          Push <Share /> above.
        </Text>
      </Flex>
      { !event.is_public &&
        <Flex>
          <VisibilityOff />
          <Text ml={2}>
            Your event is not listed.
            &nbsp;
            <LinkInApp to="/help#listing">Learn more</LinkInApp>
          </Text>
        </Flex>
      }
      { event.is_public && !event.is_approved &&
        <Flex>
          <VisibilityOff />
          <Text ml={2}>
            Your event is not listed yet, waiting for approval.
            &nbsp;
            <LinkInApp to="/help#listing">Learn more</LinkInApp>
          </Text>
        </Flex>
      }
      { event.is_public && event.is_approved &&
        <Flex>
          <Visibility />
          <Text ml={2}>
            Your event is listed.
            &nbsp;
            <LinkInApp to="/help#listing">Learn more</LinkInApp>
          </Text>
        </Flex>
      }
    </Flex>
  )
}


import { Share } from "@mui/icons-material";
import React from "react";
import IconButton from "./IconButton";
import { RWebShare } from "react-web-share";

type EditButtonProps = {
  text: string,
  url: string,
  title: string,
  onClick: () => void
}

export default (props: EditButtonProps) => {
  const { text, url, title, onClick } = props;
  return (
    <RWebShare
      data={{
        text,
        url,
        title,
      }}
      onClick={props.onClick}
    >
      <IconButton onClick={onClick}>
        <Share/>
      </IconButton>
    </RWebShare>
  );
}
